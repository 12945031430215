<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="products_loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon icon="magnify" size="is-small" />
          </b-button>
          <b-input
            ref="search"
            v-model="headers[2].input"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            :placeholder="$t('search')"
            icon="magnify"
            rounded
            :size="$device.mobile ? 'is-small' : ''"
            @input="getProducts()"
            @blur="hideMobile()"
          />
        </div>
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>

          <b-dropdown-item
            v-if="$can('create', 'catalog')"
            aria-role="listitem"
            has-link
          >
            <router-link to="/catalog/products/create">
              <b-icon icon="plus" size="is-small" />
              {{ $t("create_product") }}
            </router-link>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'catalog.export')"
            aria-role="listitem"
            @click="exportExcel"
          >
            <b-icon icon="download" size="is-small" />
            {{ $t("export_excel") }}
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item aria-role="listitem" :focusable="false" custom>
            <b-switch
              v-model="internal"
              type="is-success"
              @input="getProducts()"
            >
              {{ $t("internal_use") }}
            </b-switch>
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item
            aria-role="listitem"
            class="pr-3"
            @click="showColumnsTogle = !showColumnsTogle"
          >
            <b-icon icon="view-column" size="is-small" />
            {{ $tc("column", 2) }}
            <b-icon
              class="is-pulled-right"
              :icon="!showColumnsTogle ? 'chevron-down' : 'chevron-up'"
              size="is-small"
            />
          </b-dropdown-item>
          <template v-if="showColumnsTogle">
            <template v-for="item in headers">
              <b-dropdown-item
                v-if="showToggle(item)"
                :key="item.key"
                aria-role="listitem"
                :disabled="item.disabled"
                :focusable="false"
                custom
              >
                <b-switch
                  v-model="item.display"
                  :disabled="item.disabled"
                  type="is-success"
                  @input="changedToggle(item.value)"
                >
                  {{ item.label }}
                </b-switch>
              </b-dropdown-item>
            </template>
          </template>
        </b-dropdown>
      </div>
    </div>

    <Card content-class="c-table">
      <div class="header-container">
        <b-select-validation
          v-model="warehouse"
          required
          size="is-small"
          :label="$tc('warehouse', 1)"
          hide-details
          style="min-width: 150px"
          @input="getProducts"
        >
          <template>
            <option v-for="opt in warehouses" :key="opt.id" :value="opt.id">
              {{ `${opt.country.country} - ${opt.currency.code}` }}
            </option>
          </template>
        </b-select-validation>
        <b-field position="is-right">
          <p v-for="item in status" :key="item.value" class="control">
            <b-button
              size="is-small"
              :type="item.value == selected_status ? 'is-primary' : ''"
              @click="
                () => {
                  selected_status = item.value;
                  getProducts();
                }
              "
            >
              {{ item.text }}
            </b-button>
          </p>
        </b-field>
      </div>
      <hr class="my-0" />
      <b-table
        :key="mappedHeaders.length"
        :data="items"
        :loading="products_loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in mappedHeaders">
          <b-table-column
            :key="head.value + '_' + head.display"
            :visible="
              head.value != 'reorder' &&
              head.value != 'logistic' &&
              ((head.value == 'stock' && $can('read', 'purchasing')) ||
                head.value != 'stock')
            "
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template v-if="head.searchable" slot="searchable">
              <template v-if="head.filter_type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  @input="getProducts()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.filter_type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  @change="getProducts()"
                >
                  <template>
                    <option
                      v-for="opt in head.filter_values"
                      :key="head.filter_id ? opt[head.filter_id] : opt"
                      :value="head.filter_id ? opt[head.filter_id] : opt"
                    >
                      {{ head.filter_text ? opt[head.filter_text] : opt }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  @change="getProducts()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-model="head.input"
                  :placeholder="
                    head.value == 'size'
                      ? getSizeLabel(head)
                      : $root.getFilterPlaceholder(head)
                  "
                  rounded
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getProducts"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.value == 'size'">
                    <b-dropdown-item
                      v-for="item in sizes_types"
                      :key="item.id"
                      aria-role="listitem"
                      @click="changeFilter(head.value, item.id)"
                    >
                      {{ item.label }}
                    </b-dropdown-item>
                  </template>
                  <template v-else>
                    <template v-if="head.filter_type == 'numeric'">
                      <b-dropdown-item
                        v-for="filter in filtersData.numeric"
                        :key="filter.value"
                        aria-role="listitem"
                        @click="changeFilter(head.value, filter.value)"
                      >
                        {{ filter.label }}
                      </b-dropdown-item>
                    </template>

                    <template v-else>
                      <b-dropdown-item
                        v-for="filter in filtersData.char"
                        :key="filter.value"
                        aria-role="listitem"
                        @click="changeFilter(head.value, filter.value)"
                      >
                        {{ filter.label }}
                      </b-dropdown-item>
                    </template>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <!-- reorder_point -->
            <template v-if="head.value == 'reorder_point'" v-slot="props">
              <span>
                {{
                  props.row[head.value] !== null ? props.row[head.value] : ""
                }}
                <b-tag
                  v-if="props.row[head.value] == null"
                  type="is-danger"
                  size="is-small"
                  outlined
                  >SKIVA</b-tag
                >
              </span>
            </template>

            <!-- delta -->
            <template v-else-if="head.value === 'delta'" v-slot="props">
              <b-tag
                size="is-small"
                :type="props.row[head.value] > 0 ? 'is-danger' : 'is-success'"
              >
                {{
                  props.row[head.value] > 0 ? props.row[head.value] : "ORDER"
                }}
              </b-tag>
            </template>

            <!-- stock -->
            <template v-else-if="head.value === 'stock'" v-slot="props">
              <b-tag :type="getStockColor(props.row[head.value])">
                {{
                  props.row[head.value] != null ? props.row[head.value] : "-"
                }}
              </b-tag>
            </template>

            <!-- overstock -->
            <template v-else-if="head.value === 'overstock'" v-slot="props">
              <b-tag
                size="is-small"
                :type="props.row[head.value] ? 'is-danger' : 'is-success'"
              >
                {{ props.row[head.value] ? "OVERSTOCK" : "OK" }}
              </b-tag>
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="
                    ($can('read', 'catalog') || $can('update', 'catalog')) &&
                    selected_status !== 'draft'
                  "
                  aria-role="listitem"
                  has-link
                >
                  <router-link :to="`/catalog/products/${props.row.id}`">
                    <b-icon icon="eye" size="is-small" />
                    {{ $t("view") }}
                  </router-link>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    $can('create', 'catalog') && selected_status !== 'draft'
                  "
                  aria-role="listitem"
                  has-link
                >
                  <router-link :to="`/catalog/products/${props.row.id}/clone`">
                    <b-icon icon="content-duplicate" size="is-small" />
                    {{ $t("duplicate") }}
                  </router-link>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    ($can('delete', 'catalog') || $can('update', 'catalog')) &&
                    selected_status !== 'draft'
                  "
                  aria-role="listitem"
                  @click="deleteRestoreProduct(props.row.prod_id)"
                >
                  <b-icon
                    :icon="
                      selected_status !== 'inactive' ? 'delete' : 'refresh'
                    "
                    :type="
                      selected_status !== 'inactive'
                        ? 'is-danger'
                        : 'is-success'
                    "
                    size="is-small"
                  />
                  {{
                    $t(
                      selected_status !== "inactive"
                        ? "delete_product"
                        : "restore_product"
                    )
                  }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    ($can('delete', 'catalog') || $can('update', 'catalog')) &&
                    selected_status !== 'draft'
                  "
                  aria-role="listitem"
                  @click="deleteRestoreSku(props.row.id)"
                >
                  <b-icon
                    :icon="
                      selected_status !== 'inactive' ? 'delete' : 'refresh'
                    "
                    :type="
                      selected_status !== 'inactive'
                        ? 'is-danger'
                        : 'is-success'
                    "
                    size="is-small"
                  />
                  {{
                    $t(
                      selected_status !== "inactive"
                        ? "delete_sku"
                        : "restore_sku"
                    )
                  }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    $can('create', 'catalog') && selected_status === 'draft'
                  "
                  aria-role="listitem"
                  has-link
                >
                  <router-link
                    :to="`/catalog/products/${props.row.prod_id}/draft`"
                  >
                    <b-icon icon="eye" size="is-small" />
                    {{ $t("view") }}
                  </router-link>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-else-if="head.value == 'id'" v-slot="props">
              <router-link
                v-if="
                  ($can('read', 'catalog') || $can('update', 'catalog')) &&
                  props.row.id != null
                "
                :to="`/catalog/products/${props.row.id}`"
              >
                <!-- :to="`/catalog/products/${props.row.id}`" -->
                {{ showValue(props.row[head.value]) }}
              </router-link>
              <span v-else> {{ showValue(props.row[head.value]) }}</span>
            </template>

            <template v-else v-slot="props">
              <b-icon
                v-if="typeof props.row[head.value] === 'boolean'"
                :icon="props.row[head.value] ? 'check' : 'close'"
                :type="props.row[head.value] ? 'is-success' : 'is-danger'"
                custom-size="mdi-18px"
              />
              <template v-else>
                {{ showValue(props.row[head.value]) }}
              </template>
            </template>
          </b-table-column>
        </template>
        <EmptyBlock slot="empty" icon="file-powerpoint-box" />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import moment from "moment";
import debounce from "debounce";

export default {
  data() {
    return {
      searchFocused: false,
      showColumnsTogle: false,
      sortingPriority: [{ field: "id", order: "asc" }],
      singleSorting: {},
      currentPage: 1,
      perPage: 50,
      perPages: [50, 200, 500],
      // perPages: [100, 200, 500, 1000],
      products_loading: true,
      total: 0,
      sizes_types: [],
      headers: [
        {
          label: this.$t("id"),
          value: "prod_id",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: false,
        },
        {
          label: this.$tc("sku", 1),
          value: "id",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("name", 1),
          value: "name",
          sortable: true,
          searchable: true,
          width: "200",
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: this.$tc("brand", 1),
          value: "brand",
          sortable: true,
          searchable: true,
          width: "200",
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: `${this.$tc("color", 1)}/${this.$tc("flavor", 1)}`,
          value: "color_flavor",
          sortable: true,
          searchable: true,
          width: "200",
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: this.$tc("supplier", 1),
          value: "supplier",
          sortable: true,
          searchable: true,
          width: "200",
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: this.$t("expire_at"),
          value: "expire_at",
          sortable: true,
          searchable: true,
          width: "150",
          filter_type: "date",
          input: null,
          filter: "is",
          display: true,
        },
        {
          label: this.$t("size"),
          value: "size",
          sortable: true,
          searchable: true,
          width: "150",
          input: "",
          filter: null,
          display: true,
        },
        {
          label: this.$t("coming_soon"),
          value: "coming_soon",
          sortable: true,
          searchable: true,
          centered: true,
          width: "100",
          filter_type: "boolean",
          input: "",
          display: false,
        },
        {
          label: this.$t("barcode"),
          value: "barcode",
          sortable: true,
          searchable: true,
          width: "300",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$t("clearance"),
          value: "clearance",
          sortable: true,
          searchable: true,
          centered: true,
          width: "100",
          filter_type: "boolean",
          input: "",
          display: false,
        },
        {
          label: this.$t("shopping_feed"),
          value: "shopping_feed",
          sortable: true,
          searchable: true,
          centered: true,
          width: "100",
          filter_type: "boolean",
          input: "",
          display: false,
        },
        {
          label: this.$t("noindex"),
          value: "noindex",
          sortable: true,
          searchable: true,
          centered: true,
          width: "100",
          filter_type: "boolean",
          input: "",
          display: false,
        },
        {
          label: this.$t("sample"),
          value: "sample",
          sortable: true,
          searchable: true,
          centered: true,
          width: "100",
          filter_type: "boolean",
          input: "",
          display: false,
        },
        {
          label: this.$tc("pack", 1),
          value: "pack",
          sortable: true,
          searchable: true,
          centered: true,
          width: "100",
          filter_type: "boolean",
          input: "",
          display: false,
        },
        {
          label: this.$t("compliance"),
          value: "compliance",
          sortable: true,
          searchable: true,
          width: "150",
          filter_type: "select",
          filter_values: [],
          input: "",
          display: false,
        },
        {
          label: this.$t("created_at"),
          value: "created_at",
          sortable: true,
          searchable: true,
          width: "150",
          filter_type: "date",
          input: null,
          filter: "is",
          display: false,
        },
        {
          label: this.$t("purchase_price"),
          value: "purchase_price",
          sortable: true,
          searchable: true,
          width: "150",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: false,
        },
        {
          label: "Stock",
          value: "stock",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("reorder"),
          value: "reorder",
          display: false,
        },
        {
          label: this.$t("sales_3m"),
          value: "sales",
          sortable: true,
          width: "150",
          input: "",
          filter: "contains",
          display: false,
          group: true,
        },
        {
          label: this.$t("reorder_point"),
          value: "reorder_point",
          sortable: true,
          width: "150",
          group: true,
          display: false,
        },
        {
          label: this.$t("quantity"),
          value: "order_quantity",
          sortable: true,
          width: "100",
          group: true,
          display: false,
        },
        {
          label: this.$t("delta"),
          value: "delta",
          sortable: true,
          width: "100",
          group: true,
          display: false,
        },
        {
          label: this.$t("overstock"),
          value: "overstock",
          sortable: true,
          width: "100",
          group: true,
          display: false,
        },
        {
          label: this.$t("logistic"),
          value: "logistic",
          display: false,
        },
        {
          label: this.$t("qty_carton"),
          value: "qty_box",
          sortable: true,
          width: "100",
          display: false,
          group: true,
        },
        {
          label: this.$t("qty_palette"),
          value: "qty_palette",
          sortable: true,
          width: "100",
          display: false,
          group: true,
        },
        {
          label: this.$t("parent_id"),
          value: "parent_id",
          sortable: true,
          width: "100",
          display: false,
          group: true,
        },
        {
          label: this.$t("nutrifacts"),
          value: "nutrifacts",
          sortable: true,
          searchable: false,
          centered: true,
          width: "150",
          filter_type: "select",
          filter_id: "id",
          filter_text: "label",
          filter_values: [
            {
              id: true,
              label: this.$t("yes"),
            },
            {
              id: false,
              label: this.$t("no"),
            },
          ],
          input: "",
          display: false,
        },
        {
          label: this.$t("hs_code"),
          value: "hs_code",
          searchable: true,
          sortable: true,
          width: "100",
          input: "",
          filter: "is",
          display: false,
        },
        {
          label: this.$t("origin_iso"),
          value: "origin_iso",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "select",
          filter_values: [],
          filter_text: "label",
          filter_id: "id",
          input: "",
          filter: "is",
          display: false,
        },
        {
          label: this.$i18n.t("actions"),
          value: "actions",
          sortable: false,
          centered: true,
          width: "80",
          display: true,
          group: true,
        },
      ],
      items: [],
      internal: false,
      selected_status: "active",
      status: [
        {
          text: this.$tc("active", 2),
          value: "active",
        },
        {
          text: this.$tc("inactive", 2),
          value: "inactive",
        },
        {
          text: this.$tc("draft", 2),
          value: "draft",
        },
      ],
      filters: {
        brand_id: null,
        color_flavor_id: null,
        supplier_id: null,
        size_id: null,
      },
      brands: [],
      colors_flavors: [],
      suppliers: [],
      sizes: [],

      warehouses: [],
      warehouse: null,
    };
  },
  computed: {
    mappedHeaders() {
      return this.headers.filter((h) => h.display);
    },
  },
  watch: {
    currentPage() {
      this.getProducts();
    },
    perPage() {
      this.getProducts();
    },
    selected_status: function () {
      this.getProducts();
    },
  },
  created() {
    this.updateTitle(this.$i18n.tc("product", 2));
    this.getProducts();
  },
  methods: {
    changedToggle(value) {
      if (value == "logistic") {
        let index = this.headers.findIndex((h) => h.value == value);
        let val = this.headers[index].display;
        this.headers[index + 1]["display"] = val;
        this.headers[index + 2]["display"] = val;
        this.headers[index + 3]["display"] = val;
      }
      if (value == "reorder") {
        let index = this.headers.findIndex((h) => h.value == value);
        let val = this.headers[index].display;
        this.headers[index + 1]["display"] = val;
        this.headers[index + 2]["display"] = val;
        this.headers[index + 3]["display"] = val;
        this.headers[index + 4]["display"] = val;
        this.headers[index + 5]["display"] = val;
      }
    },
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    showToggle(item) {
      if (
        item.group ||
        (item.value == "reorder" && !this.$can("read", "purchasing")) ||
        (item.value == "purchase_price" &&
          !this.$can("read", "purchasing.pricing")) ||
        (item.value == "stock" && !this.$can("read", "purchasing"))
      )
        return false;
      else return true;
    },
    getSizeLabel(head) {
      let size = this.sizes_types.find((s) => s.id == head.filter);
      return size ? size.label : "";
    },
    closeDrop() {
      this.$refs["dropdown"].toggle();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    showValue(value) {
      if (this.$can("read", "catalog"))
        return value !== undefined && value !== null ? value : "-";
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getProducts();
    },
    getStockColor(stock) {
      if (!stock || stock <= 2) return "is-danger";
      else if (stock <= 6) return "is-warning";
      else return "is-success";
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getProducts();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getProducts();
    },
    getProducts: debounce(function () {
      if (this.$can("read", "catalog")) {
        let api_params = "?warehouse_id=" + (this.warehouse || "");

        if (this.internal) api_params += "&internal=1";

        if (this.selected_status !== "active")
          api_params += `&status=${this.selected_status}`;

        api_params += `&page=${this.currentPage}&per_page=${this.perPage}`;

        if (this.sortingPriority.length > 1) {
          let sortArr = [];
          for (let i = 0; i < this.sortingPriority.length; i++) {
            const sort = this.sortingPriority[i];
            sortArr.push(`${sort.field}:${sort.order}`);
          }
          api_params += `&sorting=${sortArr.join(",")}`;
        } else if (this.sortingPriority.length == 1) {
          api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
        } else if (this.singleSorting.field) {
          api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
        }

        let searchArr = [];
        for (let i = 0; i < this.headers.length; i++) {
          let obj = this.headers[i];
          let key = obj.value;
          let value = obj;
          if (
            [
              "id",
              "prod_id",
              "name",
              "brand",
              "color_flavor",
              "supplier",
              "barcode",
              "hs_code",
            ].includes(value.value) &&
            value.input &&
            value.input.length != 0 &&
            value.input.length < 3
          )
            return;
          else if (value.input || typeof value.input == "boolean") {
            let obj = {
              id: key,
              selected:
                ["expire_at", "created_at"].indexOf(key) != -1
                  ? moment(value.input).format("YYYY-MM-DD")
                  : value.input,
            };
            if (value.filter) obj.operator = value.filter;
            searchArr.push(obj);
          }
        }
        if (searchArr.length)
          api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

        this.products_loading = true;

        this.$axios
          .get("catalog" + api_params)
          .then((res) => {
            this.items = res.data.list ? res.data.list : [];
            this.warehouses = res.data.filters.warehouses
              ? res.data.filters.warehouses
              : [];
            if (!this.warehouse)
              this.warehouse = this.warehouses[0] && this.warehouses[0].id;
            this.sizes_types = res.data.filters.sizes_types || [];
            let sizeHeaderIndex = this.headers.findIndex(
              (h) => h.value == "size"
            );
            if (!this.headers[sizeHeaderIndex].filter)
              this.headers[sizeHeaderIndex].filter = this.sizes_types[0].id;
            // compliance
            let index = this.headers.findIndex((h) => h.value == "compliance");
            this.headers[index].filter_values = res.data.filters.compliance;
            // origin_iso
            index = this.headers.findIndex((h) => h.value == "origin_iso");
            this.headers[index].filter_values =
              res.data.filters.countries || [];

            this.total = res.data.pagination.total;
            this.currentPage = res.data.pagination.current;
          })
          .catch((e) => this.clientError(e))
          .finally(() => (this.products_loading = false));
      }
    }, 200),
    deleteRestoreProduct(prod_id) {
      this.in_progress = prod_id;
      if (this.selected_status !== "inactive") {
        this.$axios
          .delete("catalog/product/" + prod_id, {
            data: { warehouses: [this.warehouse] },
          })
          .then(() => {
            this.items = this.items.filter((i) => i.prod_id !== prod_id);
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch((e) => this.clientError(e))
          .finally(() => (this.in_progress = null));
      } else {
        this.$axios
          .patch("catalog/product/" + prod_id + "/restore", {
            warehouses: [this.warehouse],
          })
          .then(() => {
            this.items = this.items.filter((i) => i.prod_id !== prod_id);
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
          .catch((e) => this.clientError(e))
          .finally(() => (this.in_progress = null));
      }
    },
    deleteRestoreSku(sku) {
      this.in_progress = sku;
      if (
        this.selected_status !== "inactive" &&
        this.$can("delete", "catalog")
      ) {
        this.$axios
          .delete("catalog/sku/" + sku, {
            data: { warehouses: [this.warehouse] },
          })
          .then(() => {
            this.items = this.items.filter((i) => i.id !== sku);
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch((e) => this.clientError(e))
          .finally(() => (this.in_progress = null));
      } else if (
        this.selected_status == "inactive" &&
        this.$can("update", "catalog")
      ) {
        this.$axios
          .patch("catalog/sku/" + sku + "/restore", {
            warehouses: [this.warehouse],
          })
          .then(() => {
            this.items = this.items.filter((i) => i.id !== sku);
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
          .catch((e) => this.clientError(e))
          .finally(() => (this.in_progress = null));
      }
    },
    exportExcel() {
      this.products_loading = true;

      let api_params = "?warehouse_id=" + (this.warehouse || "");
      if (this.selected_status !== "active")
        api_params += `&status=${this.selected_status}`;

      // api_params += `&page=${this.currentPage}&per_page=${this.perPage}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        let obj = this.headers[i];
        let key = obj.value;
        let value = obj;
        if (
          [
            "id",
            "prod_id",
            "name",
            "brand",
            "color_flavor",
            "supplier",
            "barcode",
            "hs_code",
          ].includes(value.value) &&
          value.input &&
          value.input.length != 0 &&
          value.input.length < 3
        )
          return;
        else if (value.input || typeof value.input == "boolean") {
          let obj = {
            id: key,
            selected:
              ["expire_at", "created_at"].indexOf(key) != -1
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }
      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      let arr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i].value;
        const display = this.headers[i].display;
        if (display) {
          arr.push(value);
        }
      }
      api_params += `&columns=${arr.join(",")}`;

      this.$axios
        .get("catalog/export" + api_params)
        .then((res) => {
          const url = URL.createObjectURL(
            new Blob([res.data.file], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.data.filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.products_loading = false));
    },
  },
};
</script>
